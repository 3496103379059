.estimationCircleMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 10px;
  h6 {
    color: var(--dark-zero);
    font-style: normal;
    font-weight: normal;
    font-size: 1.8em;
  }
  .estimationCircleSub {
    display: flex;
    align-items: flex-end;
    .limitEstimations {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;
      .estimationTitle {
        font-weight: 500;
        font-style: normal;
        font-size: 1.4em;
        color: var(--dark-three);
      }
      .estimationValue {
        font-style: normal;
        font-weight: 500;
        font-size: 1.8em;
      }
    }
    .estimationCircle,
    .estimationCircleInner {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
    }
    .estimationCircle {
      margin: 0px 10px;
      background: linear-gradient(to right, #6600cc, #3e7bfa);
      width: 110px;
      height: 110px;
    }

    .estimationCircleInner {
      width: 100px;
      height: 100px;
      background-color: white;
      font-size: 3em;
      color: var(--dark-one);
    }
  }
}

.estimationCardMain {
  font-size: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .estimationCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;

    .estimationCardTitle {
      color: var(--dark-zero);
      font-style: normal;
      font-weight: normal;
      font-size: 1.8em;
    }
    .estimationCardPrice {
      color: var(--dark-one);
      font-style: normal;
      font-weight: normal;
      font-size: 3em;
    }
    .estimationCardPricePerM2 {
      color: var(--dark-three);
      font-weight: 500;
      font-size: 1.4em;
    }
  }
  .estimationCardSub {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4em;
    .divider {
      width: 3px;
      border-radius: 10px;
      background-color: var(--primary-main);
    }
    .limitEstimations {
      display: flex;
      flex-direction: column;
      align-items: center;
      .estimation {
        font-weight: 500;
        font-size: 1.4em;
        color: var(--dark-two);
      }
      .estimationPerM2 {
        color: var(--dark-three);
        font-size: 1em;
      }
    }
  }
  .gradientBar {
    width: 85%;
  }
}

.propertyStatusBox {
  margin-block: 20px;
  font-size: 16.5px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 26px;
    width: fit-content;
  }
  .propertyStatus {
    display: flex;
    flex-wrap: nowrap;
  }
}

.statusBox {
  text-align: center;
  margin: 10px;
  margin-top: 0;
  min-height: 140px;
  border-radius: 10px;
  background-color: var(--ligth-background);
  min-width: 120px;

  &.active {
    background-color: var(--primary-background);
    outline: 2px solid var(--primary-slim);
    .new,
    .good,
    .used,
    .bad,
    .awful {
      outline: 2px solid var(--primary-slim);
    }
  }

  .statusState {
    height: 15px;
    border-radius: 10px;
  }
  h1 {
    font-weight: normal;
    font-size: 0.9em;
    line-height: 16px;
    margin: 0;
    padding: 10px;
    color: var(--dark-one);
  }
  p {
    font-size: 0.6em;
    line-height: 13px;
    font-weight: 400;
    color: var(--dark-two);
    padding: 10px;
  }
  .new {
    background-color: #b3ffa7;
  }
  .good {
    background-color: #f4ffb1;
  }
  .used {
    background-color: #ffebb9;
  }
  .bad {
    background-color: #ffcbb5;
  }
  .awful {
    background-color: #fdb1b1;
  }
}
.ant-popover-inner {
  padding: 0.5em;
  padding-inline: 1em;

  .ant-btn {
    padding-inline: 1.5em;
  }
}

@media (max-width: 767px) {
  .propertyStatusBox {
    min-height: 160px;
    -webkit-transition: min-height 200ms ease-in-out;
    -moz-transition: min-height 200ms ease-in-out;
    -ms-transition: min-height 200ms ease-in-out;
    -o-transition: min-height 200ms ease-in-out;
    transition: min-height 200ms ease-in-out;

    &:hover {
      min-height: 260px;
    }
  }
  .statusBox {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    max-height: 100px;
    align-items: center;
    -webkit-transition: min-height 100ms ease-in-out;
    -moz-transition: min-height 100ms ease-in-out;
    -ms-transition: min-height 100ms ease-in-out;
    -o-transition: min-height 100ms ease-in-out;
    transition: min-height 100ms ease-in-out;
    position: relative;
    .statusState {
      width: 100%;
    }
    h1 {
      margin-top: 1.5em;
    }
    p {
      position: absolute;
      transition: opacity 500ms ease-in-out;
      transition: top 500ms;
      opacity: 0;
    }
    &:hover {
      min-height: 200px;
      p {
        top: 38%;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 750px) {
  .propertyStatusBox {
    width: 100vw;
    margin-block: auto;
    max-width: 100%;
    .statusBoxTitle {
      margin-left: 2em;
    }
    .propertyStatus {
      width: fit-content;
      margin-inline: auto;

      .statusBox {
        min-width: 100px;
        width: 100px;
        font-size: 16px;
        h1 {
          font-size: 1em;
        }
      }
    }
  }
}

@media (max-width: 610px) {
  .propertyStatusBox {
    .statusBoxTitle {
      font-size: 14px;
    }
    .propertyStatus {
      &:hover {
        min-height: 280px;
      }

      .statusBox {
        min-width: 80px;
        width: 80px;
        font-size: 14px;
        &:hover {
          min-height: 230px;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .propertyStatusBox {
    .statusBoxTitle {
      font-size: 12px;
    }
    .propertyStatus {
      width: 100%;
      display: flex;
      justify-content: center;

      .statusBox {
        font-size: 12px;
        min-height: 70px;
        margin-inline: 0.2em;

        &:hover {
          min-height: 230px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .propertyStatusBox {
    .propertyStatus {
      .statusBox {
        min-width: 62px;
        width: 62px;
        font-size: 9px;
        min-height: 70px;
        margin-inline: 0.2em;

        p {
          font-size: 0.8em;
        }
        &:hover {
          min-height: 230px;
        }
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;