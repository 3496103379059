.modal-auth .ant-modal-body,
.drawer-auth .ant-drawer-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .google-auth-divider {
    margin: 4px;
    color: var(--dark-three);
  }

  .footer-link {
    margin-top: 16px;
    span {
      text-decoration: underline;
    }
  }
}

.drawer-auth {
  z-index: 9;
}

.auth-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: var(--primary-main);
    font-size: 32px;
    text-align: center;
    margin: auto;
    line-height: normal;
    margin-bottom: 32px;
  }

  .ant-steps {
    margin-bottom: 32px;
    width: 400px;
  }

  .auth-form {
    width: 100%;
    display: grid;
    justify-content: stretch;
    justify-items: center;
    gap: 8px;

    .ant-form-item {
      width: 100%;
    }
  }

  .auth-forgot-container {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
  }
}

.modal-scrollable{
  overflow-y: scroll;
  max-height: 400px;
}

.row-display {
  display: flex;
  flex: 1;
  min-height: 100px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px 0px;
  position: relative;

  .display-element {
    margin: 25px;
    width: 150px;
    height: 100%;
    margin-bottom: 0px;
    
    button{
      white-space: normal;
      word-wrap: break-word;
      justify-content: center;
    }

    &:hover {
      background-color: var(--primary-subtle);
    }

    .display-element-img {
      width: 150px;
      height: 150px;
      padding: 0px;
      overflow: hidden;
      border-radius: 12px;
      :hover {
        cursor: pointer;
      }
      img {
        width: 150px;
        height: 150px;
        &:before {
          content: ' ';
          display: block;
          position: absolute;
          height: 150px;
          width: 150px;
          background-image: url('../../assets/iconTassApp.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-color: white;
          background-position: center;
        }
      }
      .collection-name{
        position: fixed;
        margin-top: -50px;
        background-color: rgba(255,255,255,0.85);
        padding-left: 10px;
        padding-right: 5px;
      }
    }
    .text {
      margin-top: 15px;
      align-content: center;
      display: flex;
      font-size: 12px;
      justify-content: space-evenly;
      font-weight: 400;
    }
  }
}

.adminRegisterModal {
  padding: 20px 40px;
  .ant-row {
    margin: 0;
  }
  .ant-col {
    height: 100px;
  }
  .ant-btn {
    width: 30%;
  }
  h1 {
    color: var(--primary-main);
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    padding-bottom: 30px;
  }
  .dualRow {
    display: flex;
    justify-content: space-between;
    .ant-row {
      flex-basis: 47%;
    }
  }
  .columnRow {
    display: flex;
    flex-direction: column;
    width: 33.5%;
    align-items: flex-end;

    .ant-row {
      width: 90%;
    }
    .ant-select {
      width: 100%;
    }
  }
  .formRowThree {
    display: flex;
    justify-content: space-between;
    .ant-row {
      width: 48%;
    }
  }
  .formSubmitButton {
    display: flex;
    justify-content: center;
  }
}

.adminRegisterModal {
  padding: 20px 40px;
  .ant-row {
    margin: 0;
  }
  .ant-col {
    height: 100px;
  }
  .ant-btn {
    width: 30%;
  }
  h1 {
    color: var(--primary-main);
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    padding-bottom: 30px;
  }
  .dualRow {
    display: flex;
    justify-content: space-between;
    .ant-row {
      flex-basis: 47%;
    }
  }
  .columnRow {
    display: flex;
    flex-direction: column;
    width: 33.5%;
    align-items: flex-end;

    .ant-row {
      width: 90%;
    }
    .ant-select {
      width: 100%;
    }
  }
  .formRowThree {
    display: flex;
    justify-content: space-between;
    .ant-row {
      width: 48%;
    }
  }
  .formSubmitButton {
    display: flex;
    justify-content: center;
  }
}

.collection-modal {
  margin-bottom: 0px;
}

.collection-modal .ant-modal-header {
  padding: 7px 2px;
  .ant-modal-title {
    display: flex;
    align-items: center;
    .text {
      padding: 7px 2px;
      margin: auto;
      font-size: 20px;
      font-weight: 500;
    }
    .plus-button {
      margin-right: 5px;
      width: 32px;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
}

.collection-modal .ant-modal-body {
  padding: 0px;
  padding-right: 15px;
  .new-collection-body {
    padding-top: 30px;
    display: flex;
    overflow: hidden;
    align-content: center;

    .display-element {
      margin: auto;
      width: 155px;
      height: 100%;
      overflow: hidden;
      .display-element-img {
        width: 150px;
        height: 150px;
        border-radius: 12px;
        overflow: hidden;
      }
      .text {
        width: 95%;
        border-bottom: 0.5px solid #1c1c28;
        margin-top: 8px;
        align-content: center;
        display: flex;
      }
    }
    .new-input {
      margin: auto;
      width: 95%;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.collection-modal .ant-modal-footer {
  display: grid;
  padding: 7px 0px;
  .footer-button {
    align-self: center;
    margin: auto;
    border: 0px;
    font-size: 18px;
    font-weight: 400;
  }
}

.add-collections-popup {
  align-items: center;
  background-color: #1c1c28;
  border-radius: 12px;
  bottom: 0;
  z-index: 100;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 25vw;
  padding: 8px;
  position: fixed;

  .add-popup-text {
    text-align: center;
    width: 65%;
  }

  .add-popup-undo {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 20%;
    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  .add-collections-popup {
    width: auto;
    margin: 16px 5px;
  }
  .auth-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      margin: 0 0 32px;
    }

    .ant-steps {
      width: 100%;
    }

    .auth-form {
      gap: 0;

      div.ant-form-item-explain {
        margin-left: 4px;
        font-size: 10px;
      }
    }
  }

  .modal-auth .ant-modal-body,
  .drawer-auth .ant-drawer-body {
    .google-auth-divider {
      margin: 0;
    }

    .footer-link {
      margin-top: 8px;
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;