.ant-image-preview-mask{
  background-color:rgba(0, 0, 0, 1);
}
.ant-image-preview-switch-left{
  left: 70px;
  width: 200px;
}
.ant-image-preview-switch-right{
  right: 70px;
  width: 200px;
}
.not-found-title{
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
}
.ant-image-preview-switch-left > .anticon, .ant-image-preview-switch-right > .anticon {
  font-size: 50px;
}
.componentHeader {
  // centered title
  text-align: center;
  // title is vertically centered
  align-items: center;
  // little margin bottom
  margin-bottom: 25px;
}
.closeModalFixed {
  position: absolute;
  left: 101%;
  background-color: white;
  border-radius: 2px;
}
.propertySumaryContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;

  .propertySummaryMain {
    height: 100%;
    overflow-y: scroll;
    flex: 1;
    --side-padding: 32px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 16px var(--side-padding) 32px;

    .summaryTitleContainer {
      .propertyHeaderContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          position: relative;
          left: calc(-1 * var(--side-padding));
          width: fit-content;
          height: fit-content;
          padding: 8px var(--side-padding);
          margin: 16px 0;
          color: var(--dark-zero);
          font-size: 20px;
          background-color: var(--primary-background);
        }
      }
      .propertySubHeaderContainer {
        display: flex;
        justify-content: space-between;
        margin: 16px 0;
        height: 62px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // grid with 2 columns
        grid-template-columns: 2fr auto;
        // grid column gap
        h3{
          overflow: visible;
        }
   
        .propertyPublishedPriceNumber {
          align-items: center;
          min-width: fit-content;
          margin: 0 10px;
          .priceLabel {
            display: flex;
            align-items: center;
            span {
              font-size: 22px;
            }
          }
          h4 {
            font-size: 20px;
            color: var(--primary-main);
            margin: 0 4px;
            line-height: normal;
          }
        }
      }
    }

    .componentDivider {
      height: 100px;
      position: relative;
      width: calc(100% + 2 * var(--side-padding));
      left: calc(-1 * var(--side-padding));
      margin: 30px 0px;
      background-color: var(--primary-background);
    }

    .btnEditProp,
    .btnSaveProp {
      width: fit-content;
      height: 31px;
      left: 0px;
      top: 0px;
      margin: 24px 0;
      padding: 10px;
      background: #643eff;
      box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
      border-radius: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #ffffff;
      svg {
        height: 16px;
        width: 16px;
      }
      &:disabled {
        background-color: black;
      }
    }

    .btnBack {
      padding: 0;
      left: -4px;
    }

    .componentTitle {
      text-align: center;
      h1 {
        font-style: normal;
        font-size: 30px;
        color: #1c1c28;
      }
    }

    .mapContainer {
      height: 350px;
      position: relative;
      width: calc(100% + 2 * var(--side-padding));
      left: calc(-1 * var(--side-padding));
      overflow: hidden;

      .mapboxgl-map {
        border-radius: 0px;
      }
    }
  }

  .propertyImages {
    width: 200px;
    overflow-y: scroll;
  }

  .similarPropertiesContainer {
    .similarProperties {
      padding-block: 30px;
      gap: 30px;
    }
  }

  .inversionSimulatorContainer {
    grid-template-areas:
      'title'
      'form'
      'value';
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 1rem;
  }
}

@media (max-width: 1289px) {
  .closeModalFixed {
    display: none;
  }
  .propertySumaryContainer {
    height: 100%;
    .propertySummaryMain {
      border-radius: 0;
      --side-padding: 48px;
    }
  }
}

@media (max-width: 1023px) {
  .propertySumaryContainer {
    .propertySummaryMain {
      --side-padding: 16px;
      border-radius: 0;

      .btnEditProp {
        width: 185px;
        height: 31px;
        font-size: 15px;
      }
    }
    .propertyImages {
      width: 120px;
    }
  }
}

@media (max-width: 767px) {
  .propertySumaryContainer {
    flex-wrap: wrap;

    .propertySummaryMain::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .propertySummaryMain {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .propertySummaryMain {
      border-radius: 0;
      --side-padding: 8px;
      padding: 8px var(--side-padding);
      overflow-y: scroll;
      .summaryTitleContainer{
        .propertyHeaderContainer{
          h2{
            font-size: 16px;
          }
        }
      }

      .componentTitle {
        h1 {
          font-size: 20px;
        }
      }

      h2 {
        font-size: 14px;
        margin: 8px 0;
      }

      .propertySubHeaderContainer {
        margin: 16px 0;
        h3 {
          font-size: 14px;
          max-height: 90px;
          overflow: scroll;
        }

        .propertyPublishedPriceNumber {
          .priceLabel {
            .anticon {
              font-size: 25px;
            }
            h4 {
              font-size: 15px;
              align-items: right;
              text-align: right!important;
              margin-right: 0px;
            }
          }
          p {
            font-size: 10px;
            text-align: right;
          }
        }
        span {
          font-size: 9px;
        }
      }

      .propertyImages {
        position: relative;
        display: flex;
        margin-bottom: 8px;
        width: calc(100% + 2 * var(--side-padding));
        left: calc(-1 * var(--side-padding));
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        .ant-image,
        img {
          height: 96px;
          width: auto;
          margin: 0 1px;
        }
      }

      .mapContainer {
        height: 200px;
      }

      .summaryDataIcons {
        padding: 8px 16px;

        span {
          font-size: 12px;

          .anticon {
            font-size: 26px;
            margin-right: 4px;
          }
        }
      }

      .summaryTable {
        gap: 8px;
        .summaryTableItem {
          font-size: 12px;
        }
      }

      .property-value-conclusion {
        font-size: 16px;
      }

      .footer {
        min-height: 40px;
      }
    }
    .inversionSimulatorContainer {
      padding: 2em;
    }
  }
}

@media (max-width: 600px) {
  .propertySumaryContainer {
    .propertySummaryMain {
      .inversionSimulatorContainer {
        .inversionSimulatorFormContainer {
          .inversionSimulatorForm {
            .ant-form .ant-form-item .ant-form-item-label,
            .ant-form .ant-form-item .ant-form-item-control {
              display: flex;
              flex-direction: column !important;
            }
            .doubleRow {
              gap: 0.5em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .propertySumaryContainer {
    .propertySummaryMain {
      .componentTitle {
        h1 {
          font-size: 22px;
        }
      }
      .summaryDataIcons {
        padding: 8px 16px;

        span {
          font-size: 10px;

          .anticon {
            font-size: 16px;
            margin-right: 4px;
          }
        }
      }
      .summaryTable {
        gap: 8px;
        grid-template-columns: 1fr;
        justify-items: center;
        .summaryTableItem {
          font-size: 14px;
          width: fit-content;
          padding-block: 5px;
          h4,
          span {
            margin: auto;
            text-align: center;
          }
          h4 {
            padding-right: 8px;
          }
        }
      }

      .inversionSimulatorContainer {
        .inversionSimulatorFormContainer {
          .inversionSimulatorForm {
            .doubleRow {
              gap: 0.5em;
            }
          }
        }
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;