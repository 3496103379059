.indicatorsView {
  align-content: start;
  height: 100%;
  overflow: hidden;

  .map-layout {
    display: contents;
    .bar-and-filters {
      display: flex;
      margin: 15px;
      justify-content: space-between;

      .select-direction-bar-container {
        margin: 0 4px;
      }
      .filterToggle {
        flex: content;
        margin: 0 4px;
        max-width: max-content;
        min-width: max-content;
      }
      .filterPopover {
        flex: content;
        margin: 0 4px;
      }
      .ant-select {
        flex: content;
        margin: 0px 4px;
      }
    }

    .map-container {
      position: relative;
      overflow: hidden;
      height: 100%;

      .mapboxgl-map {
        border-radius: 0px;
      }
    }
    .map-loading::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #93a2d360;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  padding: 6px 32px 6px;
  margin-right: 15px;

  background-color: var(--primary-background);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  vertical-align: middle;
}

.tools {
  height: auto;
  padding-top: 20px;
  align-items: center;
}

.description {
  height: auto;
}

.selected-zone {
  align-self: center;
  padding-left: 25px;
  width: 100%;
  font-weight: 500px;
  font-size: 18px;
}

.custom {
  background-color: #f8f8ff;
  font-size: 15px;
  font-weight: 400;
  height: 35px;
}

.indicators {
  padding-top: 10px;
}

.active.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-top: 3px solid rgb(143, 14, 14);
  background-color: aqua;
}

.selectRangeDateFilter {
  margin-top: 10px;
  margin-right: 10px;
  width: 18vw;
}

.area {
  position: absolute;
  bottom: 1px;
  width: 90%;
  margin: -1px 5%;
  background-color: #f8f8ff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .chart2 {
    padding: 10px;
  }
  .ui-datepicker {
    margin-left: 100px;
    z-index: 1000;
  }
  .calendar {
    margin-right: 90%;
  }
}
.loading::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #9c9c9c60;
  border-radius: 15px;
}
.leyenda {
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  height: fit-content;
  width: fit-content;
  max-width: 260px;
  min-height: fit-content;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 15px -5px #8186f7;
  h3 {
    text-align: center;
    font-size: medium;
  }

  .valores {
    display: grid;
    grid-template-columns: fit-content max-content fit-content;
    p {
      text-align: center;
      font-size: small;
      margin-bottom: 0px;
    }
    #min {
      grid-column: 1;
      text-align: center;
    }
    #max {
      grid-column: 3;
      text-align: center;
    }
  }
  .colores {
    border-radius: 10px;
    display: flex;
    height: fit-content;
    margin: 5px auto;
    width: fit-content;
    overflow: hidden;
    .color {
      width: 30px;
      height: 20px;
    }
    #color-1 {
      background-color: rgb(248, 177, 149);
    }
    #color-2 {
      background-color: rgb(232, 153, 141);
    }
    #color-3 {
      background-color: rgb(216, 129, 133);
    }
    #color-4 {
      background-color: rgb(145, 102, 129);
    }
    #color-5 {
      background-color: rgb(99, 97, 127);
    }
    #color-6 {
      background-color: rgb(53, 92, 125);
    }
  }
}

.loading-map {
  padding: 10px;
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -110px auto auto -175px;
  height: 230px;
  width: 350px;
  min-height: fit-content;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 15px -5px #8186f7;
  h3 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  .loadingComponent {
    position: unset;
    top: unset;
    left: unset;
    margin: unset;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;