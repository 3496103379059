.infoCardContainer {
  width: 100%;
  border-radius: 12px;
  padding: 1em;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 1em;
  padding-inline: 2em;
  .infoCardIcon {
    font-size: 3.5em;
    max-width: 20%;
  }
  .infoContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: space-between;
    min-height: 100%;
    h3,
    h4,
    h5 {
      text-align: center;
      margin: 0;
      padding: 0;
    }
    h3 {
      font-size: 1.5em;
      font-weight: normal;
      color: var(--dark-two);
    }
    h4 {
      font-size: 2em;
      font-weight: 600;
      color: var(--dark-one);
    }
    h5 {
      font-size: 1em;
      color: var(--dark-two);
    }
  }
  &.border {
    box-shadow: 0px 26px 20px rgba(108, 73, 172, 0.035), 0px 13px 11px rgba(108, 73, 172, 0.03),
      0px 5px 4px rgba(108, 73, 172, 0.02);
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;