.propertySummaryInfo {
  .mapContainer {
    height: 350px;
    position: relative;
    width: calc(100% + 2 * var(--side-padding));
    left: calc(-1 * var(--side-padding));
    margin-bottom: 60px;
    margin-top: 60px;
    overflow: hidden;
    .mapboxgl-map {
      border-radius: 0px;
    }
  }
  .summaryDescription {
    font-size: 16px;
    border: 1px solid var(--primary-lavander);
    border-radius: 12px;
    margin: 40px 0;
    padding: 24px;
    .summatyDescriotionInner {
      // min height based on content
      min-height: max-content;
      &::-webkit-scrollbar {
        width: 2px;
      }
    }
  }
  .summaryTable {
    border: 1px solid var(--primary-lavander);
    border-radius: 12px;
    margin: 16px 0;
    padding-inline: 40px;
    padding-block: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .summaryTableItem {
      font-size: 16px;
      line-height: normal;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      h4 {
        display: inline;
        font-weight: normal;
        min-width: fit-content;
        margin: 0;
        margin-right: 8px;
      }
      span {
        min-width: fit-content;
        color: var(--dark-three);
        display: block;
      }
    }
  }
  .summaryDataIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px var(--side-padding);
    border-bottom: 1px solid var(--primary-subtle);
    position: relative;
    width: calc(100% + 2 * var(--side-padding));
    left: calc(-1 * var(--side-padding));
    padding-bottom: 10px;
    span {
      color: var(--dark-two);
      font-size: 18px;
      display: flex;
      align-items: center;

      .anticon {
        font-size: 24px;
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .propertySummaryInfo {
    .summaryTable {
      .summaryTableItem {
        font-size: 14px;
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;