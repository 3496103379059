.appraisalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  max-width: 1980px;
  margin-inline: auto;
  overflow: hidden;
  .componentTitle {
    display: none;
  }

  .appraisalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding-right: 2em;
    margin-top: 3em;
    margin-bottom: 1em;
    background-color: var(--ligth-background);

    h2,
    h3 {
      margin: 0;
      font-weight: normal;
    }

    h2 {
      background-color: var(--primary-background);
      color: var(--dark-zero);
      font-size: 18px;
      padding-right: 10px;
      padding-left: 25px;
      padding-block: 10px;
      min-width: fit-content;
    }

    h3 {
      padding-inline: 10px;
      color: var(--dark-one);
      font-size: 16px;
    }
    .appraisalButtonActions {
      display: flex;
      gap: 10px;
    }
  }
  .appraisalInfo {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-block: 30px;
    width: 100%;
    .propertySummaryInfo {
      width: clamp(700px, 50vw, 900px);
      .mapContainer {
        height: 300px;
        .mapboxgl-map {
          border-radius: 10px;
        }
      }
    }
    .TassappPropertyEstimationsContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      min-height: 750px;
      width: clamp(650px, 40vw, 900px);

      h1 {
        display: none;
      }
    }
  }

  .appraisalSimilarProperties {
    width: 100%;
    .appraisalSimilarFilters {
      padding-block: 20px;
      padding-inline: 40px;
    }
    .similarPropertiesContainer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      align-items: flex-start;
      padding-inline: 40px;
      padding-block: 20px;

      .similarPropertiesMap {
        margin-top: 0px;
        width: clamp(700px, 50vw, 900px);
        height: 700px;
        overflow: hidden;
      }
      .similarPropertiesCardContainer {
        width: clamp(700px, 50vw, 900px);
        min-height: 640px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow-x: hidden;
      }
      .similarProperties {
        height: 700px;
        justify-content: space-between;
      }
      .componentTitle {
        display: none;
        width: 0px;
      }
    }
  }
  .inversionSimulatorContainer {
    padding-inline: 8em;
    margin-block: 2rem;
    display: flex;
    flex-direction: column;
    .inversionSimulatorFormContainer {
      display: flex;
      gap: 4rem;
      .inversionSimulatorForm {
        min-width: 50%;
      }
    }
  }
}

@media (max-width: 1310px) {
  .appraisalSimilarProperties {
    .similarPropertiesContainer {
      flex-wrap: wrap;
      justify-content: space-between;

      .similarPropertiesMap {
        min-width: 80%;
      }
      .similarProperties {
        height: 800px;
        margin-top: 20px;
        min-width: 85%;
      }
    }
    .similarPropertiesCardContainer {
      min-width: 100%;
      padding-top: 0px;
    }
  }
  .inversionSimulatorContainer {
    .inversionSimulatorFormContainer {
      flex-direction: column;
    }
  }
}

@media (max-width: 1023px) {
  .appraisalContainer {
    .appraisalTitle {
      flex-wrap: nowrap;
    }
    .appraisalSimilarProperties {
      .similarPropertiesContainer {
        .similarPropertiesMap {
          width: 90%;
          height: 500px;
        }
        .similarPropertiesCardContainer {
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .appraisalContainer {
    font-size: 0px;
    .appraisalTitle {
      flex-wrap: wrap;
      gap: 10px;
      padding-bottom: 20px;
      h2,
      h3 {
        width: 100%;
        text-align: center;
      }
      .appraisalButtonActions {
        margin-inline: auto;
      }
    }
    .appraisalInfo {
      .TassappPropertyEstimationsContainer {
        min-width: unset;
        width: 100vw;
      }
    }
  }

  .propertySummaryInfo {
    .mapContainer,
    .pointsOfInterestContainers {
      width: 95vw;
      margin-inline: auto;
      padding-inline: 0;
    }
    .summaryTable {
      width: 90%;
      margin-inline: auto;
      .summaryTableItem {
        font-size: 12px;
      }
    }
  }

  .inversionSimulatorFormContainer {
    margin-inline: 2em;
  }
}

@media (max-width: 650px) {
  .appraisalContainer {
    .appraisalSimilarProperties {
      .similarPropertiesContainer {
        .similarProperties {
          height: 400px;
        }
        .similarPropertiesMap {
          width: 90%;
          height: 400px;
        }
        .similarPropertiesCardContainer {
          min-height: unset;
          max-height: 350px;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          overflow-x: scroll;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .appraisalContainer {
    .appraisalSimilarProperties {
      .similarPropertiesContainer {
        padding-inline: 1rem;
        .similarPropertiesMap {
          width: 100%;
          height: 400px;
        }
      }
    }
  }

  .propertySummaryInfo {
    .summaryTable {
      gap: 8px;
      grid-template-columns: 1fr;
      justify-items: center;

      .summaryTableItem {
        font-size: 14px;
        width: fit-content;
        padding-block: 5px;
        h4,
        span {
          margin: auto;
          text-align: center;
        }
        h4 {
          padding-right: 8px;
        }
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;