.componentTitle {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 2em;
}
.propertyHistory {
  .propertyHistoryData {
    margin-top: 4em;
    .propertySellHistory {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 1.5em;
      .propertySellHistoryInner {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 1.4em;
        color: var(--dark-one);
        width: 40%;
      }
      .propertySellHistoryInnerValue {
        font-style: normal;
        font-weight: 600;
        font-size: 1.4em;
      }
    }
  }
}

@media (max-width: 750px) {
  .propertyHistory {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .propertyHistory {
    font-size: 12px;
  }
}

@media (max-width: 460px) {
  .propertyHistory {
    font-size: 11px;
    .propertyHistoryData {
      .propertySellHistory {
        .propertySellHistoryInnerValue {
          font-size: 1.8em;
        }
      }
    }
  }
}

@media (max-width: 460px) {
  .propertyHistory {
    font-size: 10px;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;