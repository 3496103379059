.appraisal-modal {
  top: 80px;
  max-height: 800px;
  min-height: 640px;

  .ant-modal-content {
    height: 100%;
    overflow: hidden;

    .ant-modal-body {
      height: 100%;
      padding: 0;
      padding-top: 3em;
    }
  }
}

.appraisal-drawer {
  z-index: 9;
  .ant-drawer-body {
    padding: 0;

    .btn-go-back {
      color: black;
      position: absolute;
      font-size: 14px;
      top: 4px;
      width: auto;
      background: transparent;

      span:not(.anticon) {
        margin-left: 4px;
      }
    }
  }
}

.appraisal-content {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 560px 1fr;

  .appraisal-form {
    display: flex;
    flex-direction: column;
    padding: 20px;

    h1,
    p {
      color: var(--dark-primary);
      text-align: center;
      width: 360px;
      margin: 0 auto;
    }
    h1 {
      font-size: 36px;
      font-weight: 600;
    }

    .ant-form {
      flex: 1;
      margin-top: 16px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 16px;
      align-content: space-between;

      .ant-form-item {
        margin-bottom: 15px;
      }

      .ant-form-item-explain {
        display: none;
      }

      .col-2 {
        grid-column-end: span 2;
      }

      .inline {
        justify-content: center;
        flex-direction: row;
        align-items: center;
        .ant-form-item-label {
          margin-right: 8px;
          padding: 0;
          flex: unset;
        }
        .ant-form-item-control {
          flex: 0;
        }
      }

      .ant-switch {
        background-color: var(--primary-lavander);
        &.ant-switch-checked {
          background-color: var(--primary-main);
        }
      }

      button.ant-btn-primary {
        grid-column-end: span 2;
        justify-self: center;
        padding: 0 24px;

        &:not(:disabled) {
          //background-color: var(--primary-main); //
          //border-color: var(--primary-main); //
        }
      }
    }
  }

  .map-container {
    margin-top: 24px;
    padding: 24px;
    padding-left: 0;
  }
}

input,
.ant-select:not([value='']),
.select-direction-bar-container {
  & ~ .label-float {
    top: 0%;
    opacity: 1;
    color: var(--dark-secondary);
    font-size: 10px;
    background: white;
  }

  span & {
    & ~ .label-float {
      top: 0%;
      opacity: 1;
      color: var(--primary-slim);
      background: white;
    }
  }
}

.label-float {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  pointer-events: none;
  transition: 0.2s ease all;
  z-index: 1;
  color: rgb(217, 217, 217);
  line-height: 100%;
}

@media (max-width: 1200px) {
  .appraisal-drawer {
    .ant-drawer-body {
      .btn-go-back {
        top: 10px;
        background-color: white;
      }
    }
  }
  .appraisal-content {
    grid-template-rows: 1fr;
    grid-template-columns: none;
    padding-block: 2em;
    .appraisal-form {
      width: 100%;
      padding: 0;
      h1,
      p {
        max-width: 320px;
      }
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 12px;
      }

      .ant-form {
        margin-top: 0;
        display: grid;
        height: 100%;
        grid-template-rows: 35px auto;
        grid-template-columns: none;

        .ant-form-item-label {
          padding: 0;
          line-height: 16px;
        }
        input,
        .ant-select-selector {
          border-radius: 8px;
          font-size: 12px;
        }
        .ant-form-item {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .mobile-col-2 {
          grid-column-end: span 2;
        }

        .inline {
          height: 22px;
          line-height: normal;
          .ant-form-item-control-input {
            min-height: unset;
          }
        }

        label {
          font-size: 10px;
        }

        button.ant-btn-primary {
          margin-top: 4px;
        }

        .ant-steps {
          width: 90vw;
          align-content: center;
          margin: 5px auto;
          grid-row: 1;
          height: 35px;
        }
        .steps-content {
          grid-row: 2;
          margin-bottom: 0px;
          height: 100%;
          .step-one {
            height: clamp(75vh, 75vh, 75vh);
            margin-top: 10px;
            .mapboxgl-map {
              border-radius: 0;
            }
          }
          .step-two {
            display: grid;
            height: clamp(70vh, 70vh, 70vh);
            grid-template-columns: auto auto;
            column-gap: 10px;
            margin: 10px;
            margin-top: 30px;
            .input {
              grid-column-end: span 2;
            }
          }
        }
      }
    }

    .map-container {
      grid-row: 2;
      height: 100%;
      width: 100vw;
      position: relative;
    }
    .map-overlay {
      margin-top: -132px!important;
    }
    .steps-action {
      //margin: 0px auto 10px auto;
      position: relative;
      top: 0%;
      left: 0%;
      width: 100%;
      max-width: 100vw;
      //height: 100%;
      margin-top: -20px;
      text-align: center;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      button {
        background-repeat:no-repeat;
        background-color: var(--primary-main);
        border-color: var(--primary-slim);
        width: 15em;
        color: white; 
        border: 3px solid #fff;
        //margin:0 5px;
        //margin-top: 40em!important;
        margin-bottom: 4em!important;
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;