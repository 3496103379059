.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.ant-checkbox-inner{
  box-shadow: 0px 0px 1px rgb(20 21 161 / 35%), 0px 0.5px 8px rgb(96 97 212 / 42%);
              
}
.propertyPreviewCardContainer {
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgb(20 21 161 / 15%), 0px 0.5px 8px rgb(96 97 212 / 22%);
  min-width: 200px;
  height: 300px;
  // 2 row of elements grid layout
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
  // space between rows
  grid-row-gap: 0%;
  //align-items: flex-end;
  overflow: hidden;
  .propertyPreviewCardCoverContainer {
    height: 100%;
    .cardCover {
      background-size: auto 100%;
      //background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;
      height: 100%;
      // grid with 3 rows and 1 column justified to the bottom
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr;
      justify-content: space-between;
      .cardCoverImageArrows{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        :hover {
          cursor: pointer;
        }
        .arrowSelector {
          svg {
            overflow: visible;
            filter: drop-shadow( -1px -1px rgba(129, 134, 247,0.6) ) drop-shadow( 1px 1px rgba(129, 134, 247,.6) ) drop-shadow( 1px -1px rgba(129, 134, 247,.6) ) drop-shadow( -1px 1px rgba(129, 134 ,247,.6) );
            height: 18px;
            width: 18px;
            path {
              fill: #ffffff;
              stroke: #ffffff;
              stroke-width: 100px;
              stroke-dasharray: 4,4;
              stroke-linejoin: round;
            }
          }
        }
      }
      .isNotAvailable {
        // this is aligned to the bottom left corner of the parent container
        width: 100%;
        max-height: 30px;
        max-width: 134px;
        display: flex;
        align-items: flex-end;
        padding-inline: 12px;
        padding-block: 2px;
        background-color: rgba(255, 255, 255, 0.7);
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        align-self: flex-end;
        color: black;
      }
      .cardCoverIcons{
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1fr;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        .coverIcon {
          display: inline-block;
          // align icon to the top
          align-self: flex-start;
          align-content: flex-start;
          .checkboxSelector{
            line-height: 0px; // overwrite antd checkbox line height
            display: grid;
            // increase selectable area
            display: inline-block;     
            position: relative;    
            z-index: 2;   
            padding: 1em;     
            margin: -1em; 
          }
          :hover {
            cursor: pointer;
          }
        
        }
        .outlinedIcon {
          overflow: visible;
          width: fit-content;
          // alingcenter
          justify-content: center;
          svg {
            overflow: visible;
            height: 18px;
            width: 18px;
            filter: drop-shadow( 0px 0px 1px #8186f710) drop-shadow(0px 1px 2px #8186f750);
            path {
              fill: #ffffff;
              stroke: #8186f760;
              box-shadow: 0px 0px 1px rgb(20 21 161 / 35%), 0px 0.5px 8px rgb(96 97 212 / 42%);
              stroke-width: 100px;
              stroke-dasharray: 2,2;
              stroke-linejoin: round;
            }
          }
        }
      };
    }
  }
  .simple {
    height: 100%;
    .propertyPreviewCardCoverContainer {
      height: 90%;
      .cardCover {
        background-size: auto 90%;
      }
    }
  }
  .mapMarkerCard{
    width: 100%
  }
  

  .cardDataContainer {
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 14px;
    &::before,
    &::after {
      display: none;
    }
    span.cardPrice {
      color: var(--dark-zero);
      font-size: 20px;
      font-weight: 510;
      line-height: normal;
      margin-right: 8px;
    }
    .cardPublicationData {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 0px;

      span.cardPropertyType {
        font-size: 12px;
        font-weight: 300;
      }
      span.cardPropertyDate {
        font-size: 10px;
        font-weight: 300;
      }
    }
    .cardAddress {
      margin: 8px 0px;
      color: var(--dark-zero);
      font-size: 12px;
      line-height: 18px;
      height: 32px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .cardFooter {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;

      .cardFooterData {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 300;
        color: var(--dark-zero);

        svg {
          height: 16px;
          width: 16px;
          margin-right: 4px;
        }
      }
    }

  }

}
@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;