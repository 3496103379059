.userProfileDataView {
  padding-inline: 1em;
  padding-block: 2em;
  margin-inline: auto;
  font-size: 20px;
  .userProfileData {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    column-gap: 2em;
    padding: 0.5em;
    max-width: 90%;
    h2 {
      font-size: 1.1em;
      font-weight: normal;
      color: var(--dark-zero);
      grid-row-start: 1;
      grid-column: 2 span;
    }
    .userProfileAvatar {
      width: 80%;
      aspect-ratio: 1;
      grid-row-start: 1;
      grid-row-end: span 2;
      grid-column-start: 1;
      min-width: 70px;
    }
    span {
      display: flex;
      flex-direction: column;
      grid-row: 2;
      font-size: 0.9em;
      color: var(--dark-three);
      align-self: center;
      em {
        color: var(--dark-one);
      }
    }
  }
  .userProfileDataCards {
    padding-top: 3em;
    display: grid;
    gap: 2em;
    row-gap: 3em;
    grid-template-columns: 1fr 1fr;
    .infoCardContainer {
      font-size: 17px;
      h4 {
        padding-block: 0.7em;
      }
    }
  }
}

@media (max-width: 1000px) {
  .userProfileDataView {
    .userProfileDataCards {
      .infoCardContainer {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 750px) {
  .userProfileDataView {
    font-size: 15px;
    .userProfileDataCards {
      grid-template-columns: 1fr;
      padding-inline: 3em;
      .infoCardContainer {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 530px) {
  .userProfileDataView {
    font-size: 12px;
    .userProfileDataCards {
      padding-inline: 1em;
      .infoCardContainer {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 370px) {
  .userProfileDataView {
    font-size: 9px;
    .userProfileDataCards {
      .infoCardContainer {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 300px) {
  .userProfileDataView {
    font-size: 8px;
    .userProfileDataCards {
      .infoCardContainer {
        font-size: 6px;
      }
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;