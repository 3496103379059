.groupTitle {
  background-color: var(--primary-background);
  margin-top: 20px;
  padding: 6px 10px 6px 100px;
  width: 350px;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 0px;
  }
}
.groupBody{
  padding: 50px 10px 6px 100px;
  text-align: center;
}
.groupTable {
  position: relative;

  width: 90%;
  max-width: 1475px;
  margin-inline: auto;

  z-index: 100;

  border-collapse: separate;
  border-spacing: 0 1.5em;

  tbody {
    tr {
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
      &:hover {
        box-shadow: 0px 0px 1px var(--primary-lavander), 0px 2px 8px var(--primary-lavander);
      }
      & td:first-child {
        padding-left: 3em;
      }
      td {
        font-size: 0.9em;
        font-weight: 300;
        padding-inline: 1em;
        padding-block: 2em;
        .ant-btn {
          border: none;
        }
      }
      .checkCell {
        .checkContainer:hover {
          cursor: pointer;
        }
        .ant-checkbox-wrapper {
          margin: auto;
        }
      }
    }
    tr.removing {
      opacity: 0.8;
      background-image: linear-gradient(
        90deg,
        transparent 0%,
        hsl(0, 0%, 95%) 20%,
        hsl(0, 0%, 90%) 50%,
        hsl(0, 0%, 95%) 80%,
        transparent 100%
      );
      pointer-events: none;
    }
    tr.deleted {
      animation-name: deletedItemFade;
      animation-timing-function: linear;
      animation-duration: 1000ms;
      animation-fill-mode: forwards;
      pointer-events: none;
    }
  }

  .ant-btn {
    background-color: transparent;
  }
}
.groupPicture{
  padding: 10px !important;
  max-height: 100px;
  max-width: 200px;
  img{
    max-height: inherit;
  }
}
@keyframes deletedItemFade {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.01;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;