.collectionsContainer {
  width: 100%;
  overflow-y: auto;

  .noMargin {
    margin-top: 0;
    padding-block: 2em;
  }
  
  .anticon path {
    fill: var(--dark-one);
  }
}

@media (max-width: 1450px) {
  .collectionsContainer {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .collectionsContainer {
    font-size: 12px;
    .anticon {
      font-size: 0.9em;
    }
  }
}

@media (max-width: 580px) {
  .collectionsContainer {
    font-size: 10px;
    .anticon {
      font-size: 0.8em;
    }
  }
}

@media (max-width: 400px) {
  .collectionsContainer {
    .ant-btn-icon-only {
      width: 20px;
      margin-left: 0;
    }
    .anticon {
      font-size: 0.7em;
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;