.btn-second {
  .ant-btn {
    background-color: var(--primary-slim);
    border-color: var(--primary-slim);
  }
  .ant-btn:hover {
    background-color: #6f76f6;
  }
}

.closeFilterButton,
.closeFilterButtonSmall {
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  color: var(--dark-one);
  margin-right: 20px;
  &:hover {
    color: var(--primary-main);
    border-color: var(--primary-slim);
  }
}
.closeFilterButtonSmall {
  font-size: 10px;
}

.viewToggle {
  height: 40px;
  width: 80px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);

  .toggleIcon {
    width: 50%;
    border-radius: 12px;
    padding: 0px 8px;
  }

  .toggleIconActive {
    width: 50%;
    border-radius: 12px;
    padding: 0px 8px;
    background-color: var(--primary-lavander);
  }
}

@media (max-width: 1650px) {
  .viewToggle {
    height: 32px;
    width: 72px;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;