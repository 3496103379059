.filterPopover {
  display: flex;
  justify-content: center;
}

.filterToggle {
  display: block;
  justify-content: center;
  .active {
    color: var(--primary-main);
  }
}

.ant-popover-inner {
  overflow: hidden;
  .ant-popover-inner-content {
    padding: 0px;
  }
}

.selectFilterInput,
.sortFilterContent {
  min-width: 200px;

  .selectFilterItem,
  .sortFilterItem {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 12px;

    &:hover {
      cursor: pointer;
      background-color: var(--primary-background);
    }
  }

  .sortFilterCategory,
  .selectFilterItem {
    border-bottom: 1px solid #e1e1f2;
  }

  .active {
    color: var(--primary-main);
  }
}

.rangeFilterContent,
.sliderFilterContent {
  h3 {
    margin-bottom: 15px;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--dark-three);
  }

  padding: 16px 24px;
  min-width: 280px;

  .button {
    padding-top: 15px;
    width: 35%;
    margin: auto;
  }
}

.rangeFilterContent {
  padding: 16px;
  .ant-input-prefix {
    font-size: 12px;
    padding-top: 2px;
  }
}

.range-filter-input-container {
  margin: 8px 0;

  &,
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-input-affix-wrapper {
    width: 44%;
    font-weight: 400;
    font-size: 16px;
    color: var(--dark-one);
    border: 1px solid #e1e1f2;
    opacity: 0.85;
    &.alignRight > input {
      text-align: right;
    }
  }

  // Hide controls
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.sortFilterCategory {
  .sortFilterItem {
    padding: 8px 16px;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;