.rows-1 {
  grid-template-rows: repeat(1, auto)
}
.rows-2 {
  grid-template-rows: repeat(2, auto)
}
.rows-3 {
  grid-template-rows: repeat(3, auto)
}
.rows-4 {
  grid-template-rows: repeat(4, auto)
}
.landingPage {
  max-height: 90vh;
  padding-bottom: 5%;
  overflow-x: hidden;
  background-image: url('../../../assets/Vector7.svg');
  background-position: right 0px bottom -300px;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  padding-top: 5%;
  // each element fill a row
  display: grid;
  grid-template-columns: auto;
  // space between rows
  grid-row-gap: 25%;
  img {
    width: 100%;
    min-width: 28vw;
    //box-shadow: 0px 0px 10px 0px #8186f75f;
    box-shadow: 0px 0px 20px 0px #8186f7;
    box-shadow: 0px 0px 30px 0px #8187f77a;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .ant-btn-primary {
    width: 250px;
    height: 49px;
    max-width: 100%;
    background: #643EFF;
    /* Light / Elevation 05 */
    box-shadow: 0px 0px 24px rgba(201, 202, 235, 0.5);
    border-radius: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-transform: uppercase;
    /* Light / Light 4 */
    color: #FFFFFF;
    /* Light / Elevation 05 */
    text-shadow: 0px 0px 24px rgba(201, 202, 235, 0.5);
    // text should be centered
    justify-content: center;
  }
  .landingPageRow {
    // each element of the row fill a column
    display: grid;
    margin-left: 5%;
    margin-right: 5%;
    align-items: center;
    align-content: space-between;
    grid-template-rows: auto;
    max-height: fit-content;
  }
  .cols-1 {
    grid-template-columns: auto;
  }
  .cols-2 {
    grid-template-columns: auto auto;
    justify-content: space-between;
    // 20px betwwen each column
    grid-column-gap: 5%;
  }
  .landingPageInputBar {
    width: 100%;
    display: grid;
    grid-column-start: 1;
    // grid column end is defined dinamically by the number of child elements
    grid-column-end: auto;
    // grid columns are defined dinamically by the number of child elements
    grid-template-columns: 3fr auto auto;
    // grid column gap
    grid-column-gap: 5%;
    justify-content: space-between;
    background-color: white;
    border-radius: 12px;
    padding: 24px 34px;
    box-shadow: 0px 0px 30px 0px #8187f77a;
    position: relative;
    .select-direction-bar-container{
      min-width: 350px;
      width: 100%;
      .ant-btn-icon-only {
        height: 100%;
      }
    }
    .ant-select, .ant-select-selector, input {
      min-height: 49px; // input height is fixed
      // text is vertically centered
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .ant-btn-primary {
      width: 100%;
      min-width: 200px;
    }
  }
  .landingPageTitle{
    position: relative;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    text-align: center;
    
    display: flex;
    align-items: center;
    justify-content: left;
    letter-spacing: 0.01em;
    // title is justified to the left
    text-align: left;
    // title is vertically centered
    align-items: center;
  }
  .landingPageTitle.main{
    top: 0;
    //width: 459px;
    //height: 47px;
    margin-bottom: 5px;
    /* Primary / Lighter */
    color: #643eff;
  }
  .landingPageTitle.secondary{
    //width: 526px;
    //height: 140px;
    margin-bottom: 5px;
    margin-top: 0px;
    /* or 141% */
    color: #1c1c28;
  }
  .landingPageText{
    position: relative;
    height: 120px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    /* Dark / Dark 0 */

    color: #1c1c28;
  }
}

@media (max-width: 1300px) {
  .landingPageTitle{
    font-size: 30px!important;
  }
}

@media (max-width: 1080px) {
}

@media (max-width: 767px) {
}
@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;