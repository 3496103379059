.publicationListContainer {
  max-width: 1920px;
  padding: 1em;
  gap: 2em;
  .publicationListMap {
    margin: auto;
    margin-top: 20px;
    width: 90%;
    height: 500px;
  }

  .publicationList {
    display: flex;
    flex-direction: column;
    padding-block: 30px;
    grid-gap: 30px;
    gap: 30px;
  }
  .publicationListCardContainer > div {
    text-align: center;
    margin-right: 15px;  /* and that, will result in a 10px gap */
  }

  .data-view-header-options {
    display: flex;
    box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);
    border-radius: 12px;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
  
    .actions {
      display: flex;
      justify-content: space-evenly;
      .ant-btn {
        border: none;
        box-shadow: none;
  
        svg path {
          fill: var(--dark-one);
        }
      }
    }
  }

  .publicationListCardContainer {
    width: 100%;
    height: 340px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    overflow-x: scroll;

    p {
      font-size: 20px;
    }

    
    .hidden {
      display: none;
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;