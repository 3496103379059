.InvestmentSimulatorContainer {
  display: grid;
  padding-inline: 3em;

  .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }
  .componentHeader {
    grid-area: title;
    font-weight: normal;
    font-size: 1.3em;
    color: var(--dark-one);
    width: 100%;
  }
  .filterToggle {
    margin-bottom: 2em;
    display: flex;
  }
  .InvestmentSimulatorForm {
    grid-area: form;
    .doubleRow {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 10%;
    }
    .ant-form-item-label {
      label {
        font-weight: normal;
        font-size: 1em;
        line-height: 15px;
        color: var(--dark-one);
        margin-left: 1ch;
      }
    }
  }
  .InvestmentSimulatorResult {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    grid-area: result;
  }

  .estimationCardMain {
    width: 100%;
  }

  .simulationResults {
    display: grid;
    grid-template-columns: 2fr 2fr;
    justify-content: space-around;
    column-gap: 1em;
    padding: 1em;
    margin-block: 1em;
    width: 100%;

    .estimatedBox,
    .diferenceBox-negative,
    .diferenceBox-positive {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1em;
      gap: 1em;
      border-radius: 20px;

      h3,
      p {
        margin: 0;
        padding: 0;
        color: white;
      }

      h3 {
        font-weight: 500;
        font-size: 1.6em;
        color: white;
      }
      p {
        font-weight: normal;
        font-size: 1.6em;
      }
      .simulationResult {
        border: none;
        text-align: center;
        background-color: transparent;
        color: white;
        font-size: 1.3em;
        padding: 0px;
        margin-block: 0;
        pointer-events: none;
      }
    }
    .estimatedBox {
      background-color: var(--primary-lighter);
    }
    .diferenceBox-negative {
      background-color: var(--red-two);
    }
    .diferenceBox-positive {
      background-color: var(--green-zero);
    }
  }
}

@media (max-width: 750px) {
  .InvestmentSimulatorContainer {
    .simulationResults {
      font-size: 12px;
      grid-template-columns: 2fr;
      padding: 1em;
      row-gap: 1em;
    }
  }
}

@media (max-width: 575px) {
  .InvestmentSimulatorContainer {
    .simulationResults {
      font-size: 10px;
    }
    .InvestmentSimulatorFormContainer {
      .InvestmentSimulatorResult {
        .estimationCardMain {
          font-size: 8px;
        }
      }
      .InvestmentSimulatorForm {
        .doubleRow {
          .ant-row {
            display: flex;
            flex-flow: row wrap;
            align-items: stretch;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .InvestmentSimulatorContainer {
    .simulationResults {
      font-size: 9px;
    }
  }
}

@media (max-width: 400px) {
  .InvestmentSimulatorContainer {
    .simulationResults {
      font-size: 8px;
    }
  }
}

@media (max-width: 350px) {
  .InvestmentSimulatorContainer {
    .simulationResults {
      font-size: 7px;
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;