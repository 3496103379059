.userAppraisalsContainer {
  width: 100%;
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
  position: relative;
  z-index: 1;
  .noMargin {
    margin-top: 0;
    padding-block: 2em;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6em;
    background-color: var(--primary-background);
  }
}

@media (max-width: 1550px) {
  .userAppraisalsContainer {
    font-size: 13px;
    .appraisalsTable {
      width: 95%;
      tbody {
        .locationCell {
          img {
            width: 12em;
          }
        }
        tr td.actionsCell {
          padding-right: 1em;
        }
      }
      .appraisalsHeader {
        & th:last-child {
          width: 100px;
          padding-right: 1em;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .userAppraisalsContainer {
    font-size: 11px;
    .appraisalsTable {
      width: 100%;
      tbody tr {
        border-radius: 0;
        .locationCell {
          img {
            border-radius: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .userAppraisalsContainer {
    .appraisalsTable {
      .appraisalsHeader {
        .locationHeader {
          display: none;
        }
      }
      tbody tr {
        .locationCell {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .userAppraisalsContainer {
    .appraisalsTable {
      .appraisalsHeader {
        & th:last-child {
          width: 70px;
        }
      }
      tbody {
        .anticon {
          font-size: 12px;
        }
      }
      tbody tr td.actionsCell {
        padding-right: 0;
      }
      .addressHeader {
        width: 30%;
      }
      .appraisalsHeader {
        .dateHeader {
          display: none;
        }
      }
      tbody tr {
        .dateCell {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .userAppraisalsContainer {
    .appraisalsTable {
      .addressHeader {
        width: 35%;
      }
      .appraisalsHeader {
        .typeHeader {
          display: none;
        }
      }
      tbody tr {
        .typeCell {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .userAppraisalsContainer {
    font-size: 9px;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;