.select-direction-bar-on-map-container {
  position: relative;
  width: 100%;

  .ant-select-selector {
    width: 90%;
  }

  .search-button {
    position: absolute;
    right: -3px;
    bottom: 0px;
    border: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 2;
    background: #8186f7;
    width: 56px;
    height: 40px;

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      font-size: 18px;
      path {
        fill: #ffffff;
      }
    }
  }
}

@media (max-width: 767px) {
  .select-direction-bar-on-map-container {
    width: 100%;
    border-radius: 8px;
    display: flex;

    .ant-select {
      width: 90%;
      font-size: 14px;
      .ant-select-selector {
        border-radius: 8px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .search-button {
      width: 36px;
      height: 30px;
      right: 4px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      background: none;

      svg {
        font-size: 15px;
        path {
          fill: #8186f7;
        }
      }
    }
  }

  .mapboxgl-ctrl-top-right {
    margin-top: 50px;

    .alert .loadingIcon {
      height: 20px;
      width: 20px;
      left: -128px;
    }

    .alert div {
      height: auto;
      padding: 0 8px;
      border-radius: 8px;
      font-size: 10px;
      width: auto;
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;