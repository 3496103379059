.appraisalsTable {
  position: relative;

  width: 90%;
  max-width: 1475px;
  margin-inline: auto;

  z-index: 100;

  border-collapse: separate;
  border-spacing: 0 1.5em;

  .appraisalsHeader {
    text-align: center;
    th {
      z-index: 10;
      font-weight: normal;
      font-size: 0.9em;
      padding-bottom: 1.5em;
      color: var(--dark-one);
    }
    & th:nth-child(n) {
      padding-inline: 1em;
    }
    & th:last-child {
      padding-right: 4em;
    }
    .addressHeader {
      width: 25%;
    }
  }

  tbody {
    text-align: center;
    .anticon path {
      fill: black;
    }
    tr {
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0px 0px 1px rgba(201, 202, 235, 0.4), 0px 2px 4px rgba(201, 202, 235, 0.4);

      & td:last-child {
        padding-right: 4em;
      }
      &:hover {
        box-shadow: 0px 0px 1px var(--primary-lavander), 0px 2px 8px var(--primary-lavander);
        cursor: pointer;
      }
    }
    tr.removing {
      opacity: 0.8;
      background-image: linear-gradient(
        90deg,
        transparent 0%,
        hsl(0, 0%, 95%) 20%,
        hsl(0, 0%, 90%) 50%,
        hsl(0, 0%, 95%) 80%,
        transparent 100%
      );
      pointer-events: none;
    }
    tr.deleted {
      animation-name: deletedItemFade;
      animation-timing-function: linear;
      animation-duration: 1000ms;
      animation-fill-mode: forwards;
      pointer-events: none;
    }
    tr td {
      padding-block: 2em;
    }
    .addressCell {
      padding-left: 2em;
      padding-right: 1em;
      text-align: left;
    }
    .actionsCell .ant-btn {
      width: fit-content;
      padding-inline: 0.5em;
    }
    .locationCell {
      padding: 0;
      img {
        border-radius: 12px;
      }
    }
  }
}

@keyframes deletedItemFade {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.01;
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;