.swipeUp {
  font-family: sans-serif;
  text-align: center;
  overflow: hidden;
}

.swipeBack {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 70vh;
}

.swipeDrawer {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.swipeContent {
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.card {
  position: absolute;
  width: 100%;
  height: 150px;
  box-shadow: 3px 3px 5px 6px #ccc;
  border-radius: 10px 10px 0 0;
  bottom: 0;
  overflow-y: hidden;
  /* transition: height 0.2s ease-in; */
}

.card-animation {
  height: 100%;
  transition: height 0.2s ease-in;
}

.card-knob {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  /* height: 20px; */
  width: 100px;
  padding: 0 10px 40px;
  border-radius: 5px;
  border-top: 6px solid #777;
  /* background-color: #777; */
  z-index: 2;
}

.card-knob:hover {
  cursor: grab;
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;