.clusterMapMarker {
  background-color: #C9CAEB;
  padding-block: 10px;
  box-shadow: 0px 0px 30px 0px #8187f749;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: space-around;

  position: absolute;
  top: -270px;
  left: -130px;

  .propertyPreviewCardContainer {
    // I want this card to be white with a glowing effect on hover
    max-width: 220px;
    height: 270px;
    background-color: white;
    .cardCover {
      // maximize space between elements vertically
      justify-content: space-between;
      align-content: flex-end;
      // maximize space between elements horizontally
      // background image full width
      background-size: cover;
    }
  }
  .changeCardArrow {
    svg {
      font-size: 40px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .arrowIcon {
    // svg with thik stroke
    svg {
      stroke-width: 2px;
      path {
        fill: #fff;
      } 
    }
  }
}

.clusterMarker {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--primary-main);
  font-weight: 500;
  border: 1px solid var(--primary-main);
  background: #fff;
  border-radius: 50%;
  padding: 10px;

  width: 30px;
  height: 30px;
  font-size: 13px;

  &.selected {
    color: #fff;
    border: 1px solid var(--primary-lighter);
    background: var(--primary-slim);
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;