// Third Component
.priceAnalysisContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1em;

  .summaryPriceAnalysisCardGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: fit-content;
    margin-inline: auto;
    column-gap: 3em;
    row-gap: 2em;
  }
  .summaryPrice {
    margin: 1.3em;
    padding: 0.8em;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2em;
    color: var(--dark-one);
    .summaryPriceValue {
      font-weight: bold;
    }
  }
  .summaryPriceMedianTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1.3em;
    color: var(--dark-zero);
    margin-inline: 1em;
    margin-bottom: 1.5em;
  }

  .summaryPriceLimit {
    display: flex;
    justify-content: space-between;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    color: var(--dark-one);

    .summaryPriceLimitValue {
      display: flex;
      flex-direction: column;
      align-items: center;
      .summaryPriceValue {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        color: var(--dark-two);
      }
      .summaryPriceValuePerM2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 1.2em;
        color: var(--dark-three);
      }
    }
  }
  .gradientBarContainer {
    padding: 5px;
    height: fit-content;

    .gradientBarMarker {
      position: relative;
      top: 25px;
      margin-top: -25px;
      height: 35px;
      width: 2px;
      background-color: var(--dark-one);
    }
    .gradientBar {
      border-radius: 20px;
      width: 95%;
      margin: auto;
      height: 15px;
      background: linear-gradient(to right, #3e7bfa, #6600cc);
    }
  }
}

@media (max-width: 900px) {
  .priceAnalysisContainer {
    .summaryPriceAnalysisCardGrid {
      .infoCardContainer {
        font-size: 14px;
      }
    }
    .summaryPriceBarContainer {
      margin-inline: auto;
      width: 90%;
    }
  }
}

@media (max-width: 800px) {
  .priceAnalysisContainer {
    .summaryPriceAnalysisCardGrid {
      .infoCardContainer {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 600px) {
  .priceAnalysisContainer {
    .summaryPriceAnalysisCardGrid {
      .infoCardContainer {
        font-size: 10px;
      }
    }
    .summaryPrice {
      font-size: 1em;
    }
    .summaryPriceBarContainer {
      font-size: 12px;
    }
  }
}

@media (max-width: 500px) {
  .priceAnalysisContainer {
    .summaryPriceAnalysisCardGrid {
      gap: 0.1em;
      .infoCardContainer {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 360px) {
  .priceAnalysisContainer {
    .summaryPriceAnalysisCardGrid {
      .infoCardContainer {
        max-width: 155px;
      }
    }
    .summaryPrice {
      font-size: 0.9em;
    }
  }
}

@primary-color: #8186F7;@font-family: Poppins -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;@text-color: #484848;@error-color: #dc5151;@success-color: #00B667;@border-radius-base: 12px;@checkbox-color: var(--primary-slim);@slider-rail-background-color: #e1e1f2;@slider-rail-background-color-hover: #dbdbf8;@slider-track-background-color: #8186f7;@slider-track-background-color-hover: #6f76f6;@slider-handle-color: #8186f7;@slider-handle-color-hover: #8186f7;@slider-handle-color-focus: tint(#8186f7, 20%);@slider-handle-color-focus-shadow: fade(#8186f7, 12%);@slider-handle-color-tooltip-open: #6f76f6;@slider-dot-border-color: #dbdbf8;@slider-dot-border-color-active: tint(#8186f7, 50%);@input-number-hover-border-color: #8186f7;@input-number-handler-border-color: #8186f7;@card-skeleton-bg: #e1e1f2;@skeleton-color: #e1e1f2;@skeleton-to-color: shade(#e1e1f2, 5%);@layout-body-background: #FFFFFF;@table-header-bg: #FFFFFF;